<template>
  <div
    class="overlay d-flex justify-content-center align-items-center flex-wrap"
  >
    <section>
      <div class="col-12 mb-10 mt-20">
        <h5>Fjärdar</h5>
        <p>
          Varje år provtas så gott som varje vattenområde i Svealand, och med
          mycket hög kvalitet. Omfattningen är unik i landet, och kunskapen om
          miljötillståndet är följaktligen mycket god.Två gånger per år, i juli
          och augusti, undersöks oceanografiska basdata; salthalt, temperatur,
          siktdjup, syrehalt, näringsämnen och klorofyll på ca 200 platser längs
          Svealands kust.
        </p>
        <p>
          Undersökningarna sker på uppdrag av
          <a href="https://www.skvvf.se/">Svealands kustvattenvårdsförbund</a>,
          en ideell organisation, vars medlemmar utgörs av kommuner,
          länsstyrelser, landsting, företag och intresseföreningar i regionen.
          Målsättningen är att bygga upp en gemensam kunskapsbas över
          miljötillståndet för att lättare kunna prioritera åtgärder för att nå
          god vattenkvalitet. Förbundet strävar också efter utökad samordning
          med olika lokala recipientkontrollprogram.
        </p>
        <p>
          Undersökningsverksamhet och rapportering leds av
          <a
            href="https://www.su.se/stockholms-universitets-ostersjocentrum/om-centrumet/samarbeten/svealands-kustvattenv%C3%A5rdsf%C3%B6rbund-1.561128?open-collapse-boxes=contact-555666777"
          >
            marin expertis från Stockholms universitet </a
          >. Institutionen för ekologi, miljö och botanik håller i den
          omfattande provtagningsverksamheten som utförs med samma höga kvalitet
          som den nationella miljöövervakningen. Institutionen inventerar,
          kvalitetsgranskar och sammanställer dessutom andra typer av relevanta
          miljödata. Allt detta underlag hålls i en databas, och utgör grunden
          för sammanställningar och miljöanalyser som görs i samverkan med
          forskare på Östersjöcentrum.
        </p>
        <p>
          Resultaten av de omfattande provtagningarna används av vattenvårdande
          myndigheter och redovisas bland annat i den årliga rapporten
          Svealandskusten och på denna webbplats.
        </p>
      </div>

      <!-- <div class="footer-button">
        <a class="rounded-btn" href="Fortsätt" @click.prevent="close">Stäng</a>
      </div> -->
    </section>

    <div class="close" @click="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#5f6368"
        stroke="#5f6368"
        stroke-width="3.232"
        stroke-linecap="round"
        xmlns:v="https://vecta.io/nano"
      >
        <path d="M3.966 3.567L21.401 21.78" />
        <path d="M3.966 21.78L21.401 3.567" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayAboutStn',
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="css" scoped>
.overlay {
  background-image: url(/img/bakgrunden10VIT.493c870a.png);
  background-color: white;
  width: 60vw;
  height: 80vh;
  z-index: 10000000000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  border-radius: 32px;
  border: #007bff 2px solid;
  padding: 10px;
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
}
.close svg {
  height: 25px;
  width: 25px;
}
.footer-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
}
section {
  padding: 20px;
  overflow: scroll;
  max-height: 100%;
  max-width: 100%;
}
h1 {
  color: #f79425;
  text-align: center;
  margin-bottom: 30px;
}
h3 {
  color: #007bff;
  margin-bottom: 5px;
}

@media only screen and (max-width: 900px) {
  .overlay {
    width: 80vw;
  }
  section {
    padding: 30px 0px;
  }
  h3 {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 1.5rem;
  }
}
</style>
