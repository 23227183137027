<template>
  <div class="locations-details-container">
    <div class="location-details mb-10">
      <div class="partial-bl-border-size-260 pb-5">
        <div class="no-padd ">
          <img
            v-if="typeOflocationData === 'stn'"
            class="drop-down-icon"
            :src="stnPil"
          />
          <img
            v-if="typeOflocationData === 'vik'"
            class="drop-down-icon"
            :src="vikPil"
          />
          <h5 class="ml-10 location-title">
            <span v-if="typeOflocationData === 'stn'"
              >Fjärden: {{ data.platsnamn }}</span
            >
            <span v-if="typeOflocationData === 'vik'"
              >Viken: {{ data.viknamn }}</span
            >
          </h5>
        </div>
      </div>

      <template v-if="data">
        <ul
          v-if="typeOflocationData === 'stn' && summary"
          class="location-specs-ul pt-5 mt-10"
        >
          <li>Kommun: {{ data.kommun }}</li>
          <li>Område: {{ data.omradesnamn }}</li>
          <li>Platsdjup: {{ data.platsdjup }} meter</li>
          <li>Första mätningen: {{ summary.firstMeasurementYear }}</li>
          <li>Stationen har funnits i {{ summary.yearsExisted }} år</li>
          <li>Latitude: {{ data.lat_dec }}</li>
          <li>Longitude: {{ data.long_dec }}</li>
        </ul>
        <template v-else>
          <div v-if="typeOflocationData === 'stn'" class="mt-5 text-center">
            Laddar..
          </div>
        </template>
        <ul
          v-if="typeOflocationData === 'vik'"
          class="location-specs-ul pt-5 mt-10"
        >
          <li>Kommun: {{ data.kommun }}</li>
          <li>Område: {{ data.omrade }}</li>
          <li>Latitude: {{ data.center_lat }}</li>
          <li>Longitude: {{ data.center_long }}</li>
        </ul>
      </template>

      <div class="buttons">
        <a class="rounded-btn" href="Tillbaka" @click.prevent="back"
          >Tillbaka</a
        >
        <a
          v-if="typeOflocationData === 'stn'"
          class="rounded-btn"
          href="Visa information"
          @click.prevent="routeToStnInfo(data.plats_id, data.platsnamn)"
          >Visa Info</a
        >
        <a
          v-if="typeOflocationData === 'vik'"
          class="rounded-btn"
          href="Visa information"
          @click.prevent="routeToVikInfo(data.data_id, data.viknamn)"
          >Visa Info</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vikPil from '@/assets/imgs/Orangepil.png'
import stnPil from '@/assets/imgs/blåpil.png'

export default {
  name: 'InfoWidget',
  props: ['data'],
  data() {
    return {
      vikPil,
      stnPil,
      summary: null
    }
  },
  async mounted() {
    if (this.typeOflocationData === 'stn') {
      const res = await axios.get(
        `https://havet-api.azurewebsites.net/GisStation/${this.data.plats_id}/summary`
      )
      this.summary = res.data
    }
  },
  computed: {
    typeOflocationData() {
      if ('viknamn' in this.data) {
        return 'vik'
      } else {
        return 'stn'
      }
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    routeToStnInfo(id, name) {
      this.$router.push({ name: 'StationInfo', params: { id: id, name: name } })
    },
    routeToVikInfo(id, name) {
      this.$router.push({ name: 'VikInfo', params: { id: id, name: name } })
    }
  }
}
</script>

<style lang="css" scoped>
.locations-details-container {
  /* position: absolute;
  top: 11px;
  right: 75px; */
  z-index: 99;
}

.location-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 100%;
  padding: 10px;
}

.location-title {
  text-align: left;
  font: normal normal normal 14px/18px Montserrat-Regular;
  letter-spacing: 0px;
  display: inline;
  color: #4b4b4b;
  opacity: 1;
}

.location-specs-ul li {
  padding-bottom: 10px;
  text-align: left;
  font: normal normal normal 14px/18px Montserrat-Regular;
  letter-spacing: 0px;
  display: block;
  color: #4b4b4b;
  opacity: 1;
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.drop-down-icon {
  /* margin-left: 15px; */
}
</style>
