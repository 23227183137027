<template>
  <div v-if="data">
    <chart :options="chartData"></chart>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import { Chart } from 'highcharts-vue'
import axios from 'axios'
import _map from 'lodash/map'
export default {
  name: 'CombinedChart',
  props: ['data'],
  components: {
    chart: Chart
  },
  data() {
    return {
      artsColor: {
        blastang: '#5E2E02',
        havsnajas: '#707070',
        kransalger: '#90ED7D',
        natevaxter: '#21521C',
        slingor: '#CC581D',
        ovriga: '#7C3687'
      }
    }
  },
  computed: {
    chartData() {
      var years = _map(this.data, 'year')

      var dataBlastang = _map(this.data, 'blastang')
      var dataHavsnajas = _map(this.data, 'havsnajas')
      var dataKransalger = _map(this.data, 'kransalger')
      var dataNatevaxter = _map(this.data, 'natevaxter')
      var dataSlingor = _map(this.data, 'slingor')
      var dataOvrigt = _map(this.data, 'ovrigt')
      var AntalArter = _map(this.data, 'Antal_arter_1')
      // debugger
      return {
        chart: {
          spacingRight: 20,
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: {
          text: '3',
          style: { display: 'none', height: '0' }
        },

        xAxis: [
          {
            categories: years,
            tickLength: 0
          }
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              style: {
                /*color: Highcharts.getOptions().colors[1]*/
              }
            },
            title: {
              text: 'Täckningsgrad i procent',
              style: {
                /*color: Highcharts.getOptions().colors[1]*/
              }
            },
            gridLineColor: '#F0f0f0',
            allowDecimals: false
          },

          {
            // Secondary yAxis
            title: {
              text: 'Antal arter',
              style: {
                /*color: Highcharts.getOptions().colors[0]*/
              }
            },
            min: 0,
            labels: {
              style: {
                /*color: Highcharts.getOptions().colors[0]*/
              }
            },
            opposite: true,
            allowDecimals: false
          }
        ],
        tooltip: {
          shared: false,
          pointFormat: '{series.name}: <b>{point.y}</b><br/>'
        },
        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'left',
          x: 120,
          verticalAlign: 'top',
          y: 100,
          floating: true,
          backgroundColor: '#FFFFFF'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0
          }
        },
        series: [
          {
            type: 'column',
            name: 'Blåstång',
            data: dataBlastang,
            color: this.artsColor.blastang
          },
          {
            type: 'column',
            name: 'Havsnajas',
            data: dataHavsnajas,
            color: this.artsColor.havsnajas
          },
          {
            type: 'column',
            name: 'Kransalger',
            data: dataKransalger,
            color: this.artsColor.kransalger
          },
          {
            type: 'column',
            name: 'Nateväxter',
            data: dataNatevaxter,
            color: this.artsColor.natevaxter
          },
          {
            type: 'column',
            name: 'Slingor',
            data: dataSlingor,
            color: this.artsColor.slingor
          },
          {
            type: 'column',
            name: 'Övriga',
            data: dataOvrigt,
            color: this.artsColor.ovriga
          },

          {
            name: 'Antal arter',
            type: 'line',
            data: AntalArter,
            yAxis: 1
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
