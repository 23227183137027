<template>
  <section class="main-content-container">
    <div class="container">
      <div class="header">
        <h4>Om Svealandskusten.se</h4>
      </div>
      <InfoText />
    </div>
  </section>
</template>

<script>
import InfoText from '@/components/InfoText.vue'
export default {
  components: {
    InfoText
  }
}
</script>

<style lang="css" scoped>
.container {
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
}
.header {
  margin-top: 40px;
  color: #007bff;
  display: flex;
  justify-content: center;
}
</style>
