<template>
  <div v-if="data">
    <chart :options="chartData"></chart>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  name: 'barChart',
  props: ['data'],
  components: {
    chart: Chart
  },
  data() {
    return {
      colors: {
        color1: '#72FF64',
        color2: '#E8D07A',
        color3: '#FF5E51',
        color4: '#E84069',
        color5: '#55C3FF',
        color6: '#BF5690',
        color7: '#733456',
        color8: '#2E838C',
        color9: '#BFB0A3',
        color10: '#F26B5E'
      },
      omvikenColor: {
        totalt: '#21394C',
        kansliga: '#54BE68',
        toleranta: '#6D97BF',
        rodlistade: '#C00D07'
      }
    }
  },
  computed: {
    chartData() {
      return {
        title: {
          text: '2',
          style: { display: 'none', height: '0' }
        },

        xAxis: {
          categories: ['Totalt', 'Känsliga', 'Toleranta', 'Rödlistade'],
          labels: {
            formatter: function() {
              return "<a href='http://www.havet.nu'>" + this.value + '</a>'
            }
          },
          tickLength: 0
        },

        yAxis: {
          title: { text: 'Antal arter' },
          gridLineColor: '#F0f0f0',
          allowDecimals: false
        },
        legend: {
          enabled: false
        },

        tooltip: {
          formatter: function() {
            return this.series.name + ': ' + this.y
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
            pointWidth: 30
          }
        },

        series: [
          /*{
                type: 'column',
                name: 'Antal arter 1',
                data: [data.antal_arter_1,0,0,0],
            },*/
          {
            type: 'column',
            name: 'Antal arter',
            color: this.omvikenColor.totalt,
            data: [this.data.antal_arter_2, 0, 0, 0]
          },
          {
            type: 'column',
            name: 'Känsliga',
            color: this.omvikenColor.kansliga,
            data: [0, this.data.antal_kansliga_arter, 0, 0]
          },
          {
            type: 'column',
            name: 'Toleranta',
            color: this.omvikenColor.toleranta,
            data: [0, 0, this.data.antal_toleranta_arter, 0]
          },
          {
            type: 'column',
            name: 'Rödlistade',
            color: this.omvikenColor.rodlistade,
            data: [0, 0, 0, this.data.antal_rodlistade_arter]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
