<template>
  <div
    class="article mb-20"
    @click="
      $router.push({ name: 'Rapport', params: { id: article.article_id } })
    "
  >
    <div class="p-2 dropp">
      <div
        class="image"
        :style="{ 'background-image': 'url(' + image + ')' }"
      ></div>
      <h6>{{ article.article_title }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: Object
  },
  computed: {
    image() {
      return (
        'https://havetstore.blob.core.windows.net/bilder/870/' +
        this.article.article_image_id +
        '.jpg'
      )
    }
  }
}
</script>

<style lang="css" scoped>
.article {
  flex-basis: 33%;
  min-width: 200px;
  max-width: 400px;
  aspect-ratio: 1;
  cursor: pointer;
}

.image {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}
.image:hover {
  opacity: 0.8;
}

.download {
  display: flex;
  justify-content: flex-end;
}
</style>
