<template>
  <div class="mt-20">
    <p class="text">
      Statusklassning av vatten är infört på EU-nivå för att göra
      miljötillståndet begripligt för alla. Klassningen görs med en femgradig
      skala. Målet är alla vattenförekomster ska ha minst god status.
    </p>
    <chart :options="chartData"></chart>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'EQR',
  props: ['title', 'unit', 'id', 'metric', 'data'],
  components: {
    Chart
  },
  data() {
    return {
      EK_HG: 0,
      EK_GM: 0,
      EK_MO: 0,
      EK_OD: 0,
      EK_BAD: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const data = this.data.data.filter(
        d => d.VARIAB_ID.toLowerCase() === this.metric.toLowerCase()
      )
      this.EK_HG =data[0].EK_HG ? parseFloat(data[0].EK_HG) : data[0].EK_HG
      this.EK_GM = data[0].EK_GM ? parseFloat(data[0].EK_GM): data[0].EK_GM
      this.EK_MO = data[0].EK_MO ? parseFloat(data[0].EK_MO) : data[0].EK_MO
      this.EK_OD = data[0].EK_OD ? parseFloat(data[0].EK_OD) : data[0].EK_OD
      this.EK_BAD =data[0].EK_OD ? parseFloat(data[0].EK_OD) - 0.1 : data[0].EK_OD
    },
    createChart() {
      const data = this.data.data
        .filter(d => d.VARIAB_ID.toLowerCase() === this.metric.toLowerCase())
        .map(d => {
          const date = Date.UTC(d.BES_AR, 1, 1)
          const value =d.EK_medel ? parseFloat(d.EK_medel) : d.EK_medel
          return [date, value]
        })

      return {
        enableMouseTracking: false,
        lineWidth: 3,
        zIndex: 3,
        name: 'EQR',
        data: data,
        color: 'rgb(0,0,0)',
        marker: null
      }
    }
  },
  computed: {
    chartData() {
      return {
        series: this.createChart(),
        plotOptions: {
          series: {
            shadow: false,
            stickyTracking: true,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          }
        },
        title: {
          text: this.title,
          x: 0,
          style: {
            fontFamily: "'Archivo Narrow',sans-serif",
            color: '#666666',
            fontSize: '16px',
            fontWeight: 'normal'
          }
        },
        xAxis: {
          dateTimeLabelFormats: {
            year: '%Y',
            month: '%Y'
          },
          startOnTick: false,
          endOnTick: true,
          tickInterval: 365 * 24 * 3600 * 1000,
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#666666',
              fontSize: '12px',
              fontWeight: 'normal'
            },
            overflow: 'justify',
            y: 25,
          }
        },
        yAxis: {
          min: 0,
          max: 1,
          gridLineWidth: 0,
          plotLines: [
            {
              color: '#FF0000',
              width: 2,
              value: 0,
              label: {
                text: 'Dålig status',
                x: -35,
                style: {
                  fontFamily: 'Helvetica,Arial,sans-serif',
                  color: '#666666',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }
              }
            },
            {
              color: '#FF8000',
              width: 2,
              value: this.EK_OD,
              label: {
                text: 'Otillfredsställande',
                x: -35,
                style: {
                  fontFamily: 'Helvetica,Arial,sans-serif',
                  color: '#666666',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }
              }
            },
            {
              color: '#FFFF00',
              width: 2,
              value: this.EK_MO,
              label: {
                text: 'Måttlig status',
                x: -35,
                style: {
                  fontFamily: 'Helvetica,Arial,sans-serif',
                  color: '#666666',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }
              }
            },
            {
              color: '#00FF00',
              width: 2,
              value: this.EK_GM,
              label: {
                text: 'God status',
                x: -35,
                style: {
                  fontFamily: 'Helvetica,Arial,sans-serif',
                  color: '#666666',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }
              }
            },
            {
              color: '#0066FF',
              width: 2,
              value: this.EK_HG,
              label: {
                text: 'Hög status',
                x: -35,
                style: {
                  fontFamily: 'Helvetica,Arial,sans-serif',
                  color: '#666666',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }
              }
            }
          ],

          labels: {
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#FFF',
              fontSize: '12px',
              opacity: 0,
              fontWeight: 'normal'
            }
          },
          title: {
            text: ' Klassgränser ',
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#666666',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          }
        },
        credits: {
          text: 'Havet.nu'
        },
        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -10,
          y: 10,
          borderWidth: 1,
          floating: true,
          backgroundColor: '#FFFFFF'
        },

        navigation: {
          buttonOptions: {
            enabled: true
          }
        },
        tooltip: {
          crosshairs: true,

          formatter: function() {
            datee = new Date(this.x)
            datee = datee.toLocaleDateString()
            return (
              '<b>' +
              this.series.name +
              '<br>Prov togs: ' +
              datee +
              '<br><b>' +
              this.y +
              '</b>(<%=Y_title%>)'
            )
          },

          animation: true,
          enabled: false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
