<template>
  <!-- Main Wrap Start -->
  <main class="position-relative main-content-container">
    <!-- <div id="map"></div> -->
    <gmap id="map" @selectedMarker="clickOnMarkerHandler" :stn="filterStn" />
    <div id="map-accordion" class="" role="tablist" aria-multiselectable="true">
      <div class="widget-container">
        <infoWidget
          class=""
          :data="currentData"
          v-if="currentData"
          @back="reset"
        />
      </div>

      <section v-if="!currentData" class="map-accordion">
        <input
          @input="delaySearch"
          v-model="search"
          style="width: 100%;z-index:99999"
          id="pac-input"
          class="controls d-lg-block"
          type="text"
          placeholder="Sök"
        />
        <div class="card no-padd boxed-dropdown stn-container d-lg-block">
          <div class="card-header" id="headingOne">
            <h5 class="drop-down-h5">
              <a
                class="sli-sec-title-map mb-0 map-drop-down-title"
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <img class="drop-down-icon" :src="stnPil" /><span class="pl-10"
                  >Fjärdar</span
                >
              </a>
            </h5>
            <p class="mb-0 explain-text" v-if="!search">
              Närmare 200 fjärdar längs hela Svealandskusten undersöks årligen
              sedan 2001. Här visas resultaten. Zooma in i kartan för att se
              bättre, och klicka på symbolerna för att få mer information. I den
              nya rutan kan du klicka på "Visa info" för att se resultaten från
              provtagningarna. Variablerna visas i flera diagram och förklaras
              kort.
              <a href="Läs mer" @click.prevent="showOverlayAboutStn = true"
                >Läs mer</a
              >
            </p>
          </div>

          <div id="collapseOne" aria-labelledby="headingOne">
            <div class="card-body">
              <ul class="locations-ul">
                <li v-if="filterStn.length === 0" class="font-italic">
                  Hittade ingen station
                </li>
                <li v-for="stn in filterStn" :key="stn.platsfilterid">
                  <a
                    :href="stn.platsnamn"
                    @click.prevent="
                      $router.push({
                        name: 'StationSelect',
                        params: { name: stn.platsnamn }
                      })
                    "
                    >{{ stn.platsnamn }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--Widget-->
    <OverlayAboutStn
      v-if="showOverlayAboutStn"
      @close="showOverlayAboutStn = false"
    />
  </main>
</template>

<script>
import dataStn from '@/data/stn.json'
import gmap from '@/components/GMap.vue'
import infoWidget from '@/components/InfoWidget.vue'
import _filter from 'lodash/filter'
import _debounce from 'lodash/debounce'
import stnPil from '@/assets/imgs/blåpil.png'
import OverlayAboutStn from '@/components/OverlayAboutStn.vue'

export default {
  name: 'Home',
  components: {
    gmap,
    infoWidget,
    OverlayAboutStn
  },
  data() {
    return {
      stnPil,
      dataStn: dataStn,
      currentData: null,
      search: '',
      searchedString: '',
      showOverlayAboutStn: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.name === 'StationSelect' || route.name === 'VikSelect') {
          this.clickToSearch(route.params.name)
        } else {
          this.reset()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    filterStn() {
      if (this.searchedString.length === 0) {
        return this.dataStn
      }
      let res = this.getFilterStn(this.searchedString)
      return res
    }
  },
  methods: {
    reset() {
      this.currentData = null
      this.search = ''
      this.searchedString = ''
    },
    getFilterStn(val) {
      return _filter(this.dataStn, d => {
        return (
          d.platsnamn.toLowerCase().includes(val.toLowerCase()) ||
          d.platskod.toLowerCase().includes(val.toLowerCase()) ||
          d.kommun.toLowerCase().includes(val.toLowerCase()) ||
          d.omradesnamn.toLowerCase().includes(val.toLowerCase())
        )
      })
    },

    clickToSearch(val) {
      this.currentData = [].concat(this.getFilterStn(val))[0]
      this.search = val
      this.searchedString = val
    },
    delaySearch: _debounce(function() {
      this.searchedString = this.search
    }, 300),

    clickOnMarkerHandler(data) {
      this.searchedString = data.platsnamn
      this.search = data.platsnamn
      this.$router.push({
        name: 'StationSelect',
        params: { name: data.platsnamn }
      })

      this.currentData = data
    }
  }
}
</script>

<style scoped>
#map {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  margin-top: 0;
  height: calc(100vh - 80px) !important;
  width: 100%;
}
.stn-container,
.vikar-container {
  max-height: 100%;
  overflow-y: auto;
}

.map-accordion {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}
.widget-container {
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
}
#map-accordion {
  position: absolute;
  width: 300px;
  margin-left: 10px;
}
@media only screen and (max-width: 768px) {
  .explain-text {
    display: none;
  }
  .map-accordion {
    align-items: flex-start;
  }
  #map-accordion {
    overflow-x: hidden;
    width: 200px;
    margin-left: 10px;
    /* width: 300px; */
  }
  .widget-container {
    width: 100%;
    z-index: 99999;
    display: flex;
    justify-content: flex-start;
  }
}
.small-screen-control {
  display: block;
}
p {
  font-size: 13px;
}

#pac-input {
  width: 100%;
  margin-bottom: 10px;
}
</style>
