<template>
  <main class="position-relative main-content-container" v-if="currentVik">
    <div class="container">
      <!-- Top section Markup  -->

      <div
        class="pl-lg-100 pr-lg-100 pt-20 pb-20 d-flex justify-content-center flex-column"
      >
        <h4
          class="font-size-32 text-center mon-rat-alt-bold color-gray-defaults "
        >
          {{ currentVik.viknamn }}
        </h4>
        <div class="text-center h5">
          <a href="https://www.havet.nu/svealandskusten/?d=3447"
            >Viken är av typen: {{ vikTyp }}</a
          >
        </div>
      </div>

      <div class=" pb-50 pt-60">
        <div class="">
          <div class="row boxed">
            <div class="col-lg-9 col-md-9 p-0">
              <div class="mapouter">
                <gmap id="map" :vikar="[currentVik]" />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 pt-20">
              <span
                class="mt-20 partial-bl-border-size-111 mon-rat-alt-reg font-size-17"
              >
                <i class="color-orange-default fas fa-map-pin"></i>
                {{ currentVik.viknamn }}</span
              >
              <ul class="location-des-ul mt-25">
                <li>Kommun: {{ currentVik.kommun }}</li>
                <li>Område: {{ currentVik.omrade }}</li>
                <!-- <li>Latitude: {{ currentVik.center_lat }}</li>
                <li>Longitude: {{ currentVik.center_long }}</li> -->
              </ul>
              <div class="text-center pt-30">
                <a
                  class="color-orange-default text-underlined mon-rat-alt-reg"
                  href="Tillbaka"
                  @click.prevent="$router.go(-2)"
                  >Tillbaka till karta</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row for Featured Article Container -->
      <div class="row">
        <div class="col-lg-2  d-none d-lg-block"></div>
      </div>
      <div class="row" v-if="imageId">
        <div class="col-lg-2  d-none d-lg-block"></div>
        <div class="col-lg-8 col-md-12">
          <img
            :src="
              'https://havetstore.blob.core.windows.net/bilder/870/' +
                imageId +
                '.jpg'
            "
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2  d-none d-lg-block"></div>
        <div class="col-lg-8 col-md-12">
          <h6 class="text-center">
            Växtsamhället<br />
            <small style="color:grey"> Inventerad år: {{ years }} </small>
          </h6>
          <PieChart :data="data" />
          <div class="text-center mt-10 mb-50">
            <a href="https://www.havet.nu/svealandskusten/?d=3449"
              >Läs mer om växtsamhällen och arterna</a
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2  d-none d-lg-block"></div>
        <div class="col-lg-8 col-md-12">
          <h6 class="text-center">
            Om viken
            <div>
              Ekologisk status:
              <span id="eko-status-badge" :class="ekoStatusBadgeClass"></span>
              {{ ekoStatus }}
            </div>
          </h6>
          <BarChart :data="data" />
        </div>
      </div>
      <div class="text-center mt-10 mb-50">
        <a href="https://www.havet.nu/svealandskusten/?d=3449"
          >Läs mer om hur ekologisk status bestäms</a
        >
      </div>
      <div class="row" v-if="dataExtended && dataExtended.length > 0">
        <div class="col-lg-2  d-none d-lg-block"></div>
        <div class="col-lg-8 col-md-12">
          <h6 class="text-center">Växtsamhället under flera år</h6>
          <CombinedChart :data="dataExtended" />
        </div>
      </div>

      <div class="row">
        <div
          class="col-12 pt-20 text-center font-size-16 mon-rat-alt-reg text-underlined "
        >
          <a
            class="color-orange-default text-underlined mon-rat-alt-reg"
            href="Tillbaka"
            @click.prevent="$router.go(-2)"
            >Tillbaka till karta</a
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import gmap from '@/components/GMap.vue'
import dataVik from '@/data/vikar.json'
import axios from 'axios'
import PieChart from '../components/chartsdiagrams/PieChart.vue'
import BarChart from '../components/chartsdiagrams/BarChart.vue'
import CombinedChart from '../components/chartsdiagrams/CombinedChart.vue'
import _filter from 'lodash/filter'
import _has from 'lodash/has'
export default {
  name: 'VikInfo',
  props: {
    name: String,
    id: Number
  },
  data() {
    return {
      currentVik: null,
      data: null,
      dataExtended: null,
      vikTyp: null,
      years: null,
      ekoStatus: '',
      imageId: null
    }
  },
  mounted() {
    let data = _filter(dataVik, s => {
      return s.data_id == this.id
    })
    console.log(data)
    this.currentVik = data[0]
    this.fetchData()
  },
  computed: {
    ekoStatusBadgeClass() {
      let badgeClass
      if (this.ekoStatus.toLowerCase() === 'hög') {
        badgeClass = 'eqr-hog'
      } else if (this.ekoStatus.toLowerCase() === 'god') {
        badgeClass = 'eqr-god'
      } else if (this.ekoStatus.toLowerCase() === 'måttlig') {
        badgeClass = 'eqr-mattlig'
      } else if (this.ekoStatus.toLowerCase() === 'otillfredställande') {
        badgeClass = 'eqr-otill'
      } else if (this.ekoStatus.toLowerCase() === 'dålig') {
        badgeClass = 'eqr-dalig'
      }
      return badgeClass
    }
  },
  methods: {
    metaInfo() {
      return {
        title: 'Svealandskusten - ' + this.currentVik.viknamn,
        meta: [
          {
            name: 'description',
            content: 'Svealandskusten - Miljöläget längs vår kust'
          }
        ]
      }
    },
    setVikData() {
      this.vikTyp = this.data.viktyp
      this.years = this.data.invent_dat
      this.ekoStatus = this.data.eqr
      this.imageId = this.data.img_id
    },
    async fetchData() {
      const res = await axios.get(
        'https://havet-api.azurewebsites.net/GisVik/' + this.id
      )
      this.data = res.data.data
      this.dataExtended = res.data.dataExtended
      this.setVikData()
    }
  },
  components: {
    gmap,
    PieChart,
    BarChart,
    CombinedChart
  }
}
</script>

<style lang="css" scoped>
#map {
  height: 300px;
}
.main-content-container {
  max-width: 900px;
  display: flex;
  justify-content: center;
  margin: auto;
}
#eko-status-badge {
  min-width: 10px;
  min-height: 10px;
  display: inline-block;
  margin-left: 10px;
}
.eqr-hog {
  background-color: #0066ff;
}
.eqr-god {
  background-color: #00ff00;
}
.eqr-mattlig {
  background-color: #ffff00;
}
.eqr-otill {
  background-color: #ff8000;
}
.eqr-dalig {
  background-color: #ff0000;
}
.boxed {
  margin: auto;
}
</style>
