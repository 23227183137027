<template>
  <main class="position-relative main-content-container" v-if="currentStn">
    <div class="container">
      <!-- Top section Markup  -->
      <div class="pl-lg-100 pr-lg-100 d-flex justify-content-center pt-30">
        <h4 class="font-size-32 mon-rat-alt-bold color-gray-defaults ">
          {{ currentStn.platsnamn }}
        </h4>
      </div>
      <div class="row pb-50 pt-60">
        <div class="col-lg-2  d-none d-lg-block"></div>

        <div class="col-lg-8 col-md-12">
          <div class="row boxed">
            <div class="col-lg-9 col-md-9 p-0">
              <div class="mapouter">
                <gmap id="map" :stn="[currentStn]" />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 pt-20">
              <span
                class="mt-20 partial-bl-border-size-111 mon-rat-alt-reg font-size-17"
              >
                <i class="color-orange-default fas fa-map-pin"></i>
                {{ currentStn.platsnamn }}</span
              >
              <ul class="location-des-ul mt-15">
                <li>Kommun: {{ currentStn.kommun }}</li>
                <li>Område: {{ currentStn.omradesnamn }}</li>
                <li>Platsdjup: {{ currentStn.platsdjup }} meter</li>
                <li>Latitude: {{ currentStn.lat_dec }}</li>
                <li>Longitude: {{ currentStn.long_dec }}</li>
                <li>Delprogram: {{ delprogram }}</li>
                <li></li>
              </ul>
              <div class="pt-60 back-link">
                <a
                  class="color-orange-default text-underlined mon-rat-alt-reg"
                  href="Tillbaka"
                  @click.prevent="$router.go(-2)"
                  >Tillbaka till karta</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row for Featured Article Container -->
      <div class="row">
        <div class="col-lg-2  d-none d-lg-block"></div>
        <div class="col-lg-8 col-md-12">
          <template v-if="!loading">
            <CollapsableCard
              v-for="card in cards"
              :key="card.title"
              :metrics="card.metrics"
              :id="id"
              :data="currentStnData"
              :collapsed="card.collapsed"
              :title="card.title"
            />
          </template>
          <div
            class="pt-20 text-center font-size-16 mon-rat-alt-reg text-underlined "
          >
            <div v-if="loading" href="" class="color-orange-default">
              Laddar data...
            </div>
            <a
              v-if="!loading"
              href="Tillbaka"
              @click.prevent="$router.go(-2)"
              class="color-orange-default"
              >Tillbaka till karta</a
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import gmap from '@/components/GMap.vue'
import CollapsableCard from '@/components/CollapsableCard.vue'
import dataStn from '@/data/stn.json'
import axios from 'axios'
import _filter from 'lodash/filter'
export default {
  name: 'StationInfo',
  props: {
    name: String,
    id: Number
  },
  components: {
    gmap,
    CollapsableCard
  },
  data() {
    return {
      currentStn: null,
      currentStnData: null,
      loading: true,
      cards: [
        {
          title: 'Salthalt',
          collapsed: false,
          metrics: [
            {
              title: 'Salthalt',
              unit: 'Salthalt i promille',
              longTitle: 'Salthalt',
              key: 'salin',
              type: 'line'
            }
          ]
        },
        {
          title: 'Temperatur',
          collapsed: false,
          metrics: [
            {
              title: 'Temperatur',
              unit: 'Temperatur i °C',
              longTitle: 'Temperatur',
              key: 'temp',
              type: 'line'
            }
          ]
        },
        {
          title: 'Syrehalt vid botten',
          collapsed: false,
          metrics: [
            {
              title: 'Syrehalt vid botten',
              unit: 'Milligram syre per liter vatten',
              longTitle: 'Syrehalt vid botten',
              key: 'o2H2S',
              type: 'line'
            }
          ]
        },
        {
          title: 'Siktdjup',
          collapsed: false,
          metrics: [
            {
              title: 'Siktdjup',
              unit: 'Siktdjup i meter',
              longTitle: 'Siktdjup',
              key: 'sikt',
              type: 'line'
            },
            {
              title: 'Ekologisk status för siktdjup',
              unit: 'Siktdjup i meter',
              longTitle: 'Ekologisk status för siktdjup',
              key: 'sikt',
              type: 'eqr'
            }
          ]
        },
        {
          title: 'Klorofyll',
          collapsed: false,
          metrics: [
            {
              title: 'Klorofyll',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Klorofyll',
              key: 'kfyllA',
              type: 'line'
            },
            {
              title: 'Ekologisk status för klorofyll',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Ekologisk status för klorofyll',
              key: 'kfyllA',
              type: 'eqr'
            }
          ]
        },
        {
          title: 'Kväve',
          collapsed: false,
          metrics: [
            {
              title: 'Kväve',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Kväve',
              key: 'ntot',
              type: 'line'
            },

            {
              title: 'Nitrat och nitrit',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Nitrat och nitrit',
              key: 'noxn',
              type: 'line'
            },
            {
              title: 'Ammonium',
              unit: 'Halter i mikrogram per liter',
              longTitle: 'Ammonium',
              key: 'nH4N',
              type: 'line'
            },
            {
              title: 'Ekologisk status för totalkväve',
              unit: 'Siktdjup i meter',
              longTitle: 'Ekologisk status för totalkväve',
              key: 'ptot',
              type: 'eqr'
            }
          ]
        },
        {
          title: 'Fosfor',
          collapsed: false,
          metrics: [
            {
              title: 'Fosfor',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Fosfor',
              key: 'ptot',
              type: 'line'
            },

            {
              title: 'Fosfat',
              unit: 'Halter i mikrogram per liter',
              longTitle: 'Fosfat',
              key: 'pO4P',
              type: 'line'
            },
            {
              title: 'Ekologisk status för fosfor',
              unit: 'Siktdjup i meter',
              longTitle: 'Ekologisk status för fosfor',
              key: 'ptot',
              type: 'eqr'
            }
          ]
        },

        {
          title: 'Kisel',
          collapsed: false,
          metrics: [
            {
              title: 'Kisel',
              unit: 'Halter i mikrogram per liter ',
              longTitle: 'Kisel',
              key: 'siO4Si',
              type: 'line'
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    delprogram() {
      switch (this.currentStn.delprogram.toLowerCase()) {
        case 'srk':
          return 'Vattenvårdsförbundens samordnade recipientkontroll'
        case 'skvvf':
          return 'Svealands Kustvattenvårdsförbund'
        case 'möv':
          return 'Miljöövervakningsprogrammet'
        case 'lstd':
          return ' Södermanlands län'
        case 'lstc':
          return 'Uppsala län'
        case 'lstab':
          return 'Stockholms län'
        default:
          return 'Saknar uppgift'
      }
    }
  },
  methods: {
    async init() {
      let data = _filter(dataStn, s => {
        return s.plats_id === this.id
      })
      this.currentStn = data[0]
      const res = await axios.get(
        'https://havet-api.azurewebsites.net/GisStation/' + this.id
      )
      this.currentStnData = res.data
      this.loading = false
    },
    metaInfo() {
      return {
        title: 'Svealandskusten - ' + this.currentStn.platsnamn,
        meta: [
          {
            name: 'description',
            content: 'Svealandskusten - Miljöläget längs vår kust'
          }
        ]
      }
    }
  }
}
</script>

<style lang="css" scoped>
/* .main-content-container {
  max-width: 900px;
  display: flex;
  justify-content: center;
  margin: auto;
} */
#map {
  height: 330px;
}
.boxed {
  margin: auto;
}
.back-link {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  left: 0;
}
</style>
