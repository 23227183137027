<template>
  <div>
    <h4>HUR FUNKAR KARTAN</h4>
  </div>
</template>

<script>
export default {}
</script>

<style lang="css" scoped></style>
