<template>
  <div class="map" id="map"></div>
</template>

<script>
import gmapsInit from '../lib/gmap.js'

export default {
  name: 'MapComponent',
  props: ['stn', 'vikar'],
  data() {
    return {
      map: null,
      google: null,
      isMounted: false,
      stationsObject: [],
      stationMarkers: [],
      selected: null
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    async init() {
      try {
        this.google = await gmapsInit()
        this.map = new this.google.maps.Map(this.$el)
        this.geocoder = new this.google.maps.Geocoder()
        this.isMounted = true
      } catch (error) {
        console.error(error)
      }
    },
    removeMarkers() {},
    clearPlaceMarks() {
      //ta bort alla placemarks
      this.stationsObject.forEach(m => {
        m.setMap(null)
      })
      this.stationsObject = []
      this.stationMarkers = []
    },

    zoomIn() {
      let bounds = new this.google.maps.LatLngBounds()
      // this.map.fitBounds(bounds)
      for (let i = 0; i < this.stationsObject.length; i++) {
        let loc = new this.google.maps.LatLng(
          this.stationsObject[i].position.lat(),
          this.stationsObject[i].position.lng()
        )
        bounds.extend(loc)
      }

      if (this.stationsObject.length === 1) {
        this.map.setZoom(12)
        this.map.setCenter(bounds.getCenter())
      } else if (this.stationsObject.length === 0) {
        this.geocoder.geocode({ address: 'Sweden' }, (results, status) => {
          if (status !== 'OK' || !results[0]) {
            throw new Error(status)
          }
          this.map.setCenter(results[0].geometry.location)
          this.map.fitBounds(results[0].geometry.viewport)
        })
      } else {
        this.map.fitBounds(bounds)
        this.map.setCenter(bounds.getCenter())
      }
    },
    setSelected(marker) {
      this.map.setCenter(marker.getPosition())
      this.selected = marker.selectedMarkerInfo
      this.$emit('selectedMarker', this.selected)
    },
    markerClick(marker) {
      let self = this
      marker.addListener('click', function() {
        self.setSelected(marker)
      })
    },
    addStnMarkers() {
      this.clearPlaceMarks()
      var placesData = this.stn
      placesData.forEach(obj => {
        var infoWindow = new this.google.maps.InfoWindow({
          content: obj.platsnamn
        })
        let lat = obj.lat_dec
        let lng = obj.long_dec
        this.stationMarkers.push({ lat: lat, lng: lng })
        var marker = new this.google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: this.map,
          title: obj.platsnamn,
          selectedMarkerInfo: {
            kommun: obj.kommun,
            plats_id: obj.plats_id,
            platskod: obj.platskod,
            platsnamn: obj.platsnamn,
            lat_dec: obj.lat_dec,
            long_dec: obj.long_dec
          }
        })
        let pinIcon = new window.google.maps.MarkerImage(
          require('@/assets/imgs/blåpil.png'),
          null /* size is determined at runtime */,
          null /* origin is 0,0 */,
          null /* anchor is bottom center of the scaled image */,
          new this.google.maps.Size(40 / 3, 62 / 3)
        )
        marker.setIcon(pinIcon)
        this.stationsObject.push(marker)
        this.google.maps.event.addListener(marker, 'mouseover', function() {
          infoWindow.open(this.map, marker)
        })
        this.google.maps.event.addListener(marker, 'mouseout', function() {
          infoWindow.close()
        })
        this.markerClick(marker)
      })
      this.zoomIn()
    }
  },
  watch: {
    stn: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        const self = this
        var worker = setInterval(function() {
          if (self.isMounted && self.stn) {
            self.addStnMarkers()
            clearInterval(worker)
          }
        }, 300)
      }
    }
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding-bottom: 0;
}
</style>
