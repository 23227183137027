<template>
  <div v-if="data">
    <chart :options="chartData"></chart>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  name: 'PieChart',
  props: ['data'],
  components: {
    chart: Chart
  },
  data() {
    return {
      colors: {
        color1: '#72FF64',
        color2: '#E8D07A',
        color3: '#FF5E51',
        color4: '#E84069',
        color5: '#55C3FF',
        color6: '#BF5690',
        color7: '#733456',
        color8: '#2E838C',
        color9: '#BFB0A3',
        color10: '#F26B5E'
      },
      artsColor: {
        blastang: '#5E2E02',
        havsnajas: '#707070',
        kransalger: '#90ED7D',
        natevaxter: '#21521C',
        slingor: '#CC581D',
        ovriga: '#7C3687'
      }
    }
  },
  computed: {
    chartData() {
      return {
        title: {
          text: 'Combination chart',
          style: { display: 'none', height: '0' }
        },
        credits: {
          enabled: false
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          floating: true,
          x: 0,
          y: 5,
          symbolWidth: 10,
          symbolHeight: 10,
          labelFormat: '{name} ({percentage:.0f}%)',
          itemStyle: {
            color: '#333333',
            cursor: 'pointer',
            fontSize: '10px',
            fontWeight: 'normal'
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          title: { text: 'Procent' },
          gridLineColor: '#F0f0f0'
        },
        xAxis: {
          min: 0,
          max: 2,
          tickColor: '#FFF',
          categories: ['Täckningsgrad i viken', '', '']
        },
        tooltip: {
          hideDelay: 0,
          formatter: function() {
            return this.point.name + '<br>' + this.percentage.toFixed(0) + '%'
          }
        },
        plotOptions: {
          pie: {
            size: '50%',
            borderWidth: 0,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          column: {
            showInLegend: false,
            pointWidth: 30,
            color: this.colors.color3
          }
        },
        series: [
          {
            type: 'column',
            name: 'Täckning medel',
            data: [this.data.tackningsgrad, { color: '#BF0B23' }]
          },
          {
            type: 'pie',
            name: '',
            data: [
              {
                name: 'Blåstång',
                y: this.data.blastang,
                color: this.artsColor.blastang
              },
              {
                name: 'Havsnajas',
                y: this.data.havsnajas,
                color: this.artsColor.havsnajas
              },
              {
                name: 'Kransalger',
                y: this.data.kransalger,
                color: this.artsColor.kransalger
              },
              {
                name: 'Nateväxter',
                y: this.data.natevaxter,
                color: this.artsColor.natevaxter
              },
              {
                name: 'Slingor',
                y: this.data.slingor,
                color: this.artsColor.slingor
              },
              {
                name: 'Övriga',
                y: this.data.ovrigt,
                color: this.artsColor.ovriga
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
