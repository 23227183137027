<template>
  <div
    class="article mb-20"
    @click="$router.push({ name: 'Page', params: { id: page.pageId } })"
  >
    <div class="p-2 dropp">
      <div
        class="image"
        :style="{ 'background-image': 'url(' + page.image + ')' }"
      ></div>
      <h6>{{ page.title }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: Object
  }
}
</script>

<style lang="css" scoped>
.article {
  flex-basis: 33%;
  min-width: 200px;
  max-width: 400px;
  aspect-ratio: 1;
  cursor: pointer;
}

.image {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}
.image:hover {
  opacity: 0.8;
}

.download {
  display: flex;
  justify-content: flex-end;
}
</style>
