<template>
  <div id="accordion" class="" role="tablist" aria-multiselectable="true">
    <div class="card" :class="{ 'card-expanded': !collapsed }">
      <div class="card-header">
        <h5 class="mb-0 d-flex justify-content-between">
          <a
            class="sli-sec-title partial-bl-border-size-185"
            :class="{ titleNoUnderline: collapsed }"
          >
            {{ title }}
          </a>
          <div
            style="cursor:pointer"
            :class="[collapsed ? 'buttonCollapsed' : 'buttonNotCollapsed']"
            @click="collapsed = !collapsed"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#F79425"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        </h5>
      </div>

      <div :class="[collapsed ? 'cardCollapsed' : 'cardNotCollapsed']">
        <div class="card-body">
          <div class="">
            <template v-for="metric in metrics">
              <lineChart
                v-if="metric.type === 'line'"
                :title="metric.longTitle"
                :unit="metric.unit"
                :id="id"
                :key="metric.key"
                :metric="metric.key"
                :data="data[metric.key]"
              />
              <EQRChart
                v-if="metric.type === 'eqr'"
                :title="metric.longTitle"
                :unit="metric.unit"
                :id="id"
                :key="metric.key + 'eqr'"
                :metric="metric.key"
                :data="data[metric.key]"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../components/chartsdiagrams/LineChart.vue'
import EQRChart from '../components/chartsdiagrams/EQRChart.vue'
export default {
  name: 'CollapsableCard',
  props: {
    id: {
      type: [String, Number]
    },
    metrics: Array,
    data: Object,
    collapsed: Boolean,
    title: String
  },
  data() {
    return {}
  },
  components: {
    LineChart,
    EQRChart
  },
  computed: {
    dataMetric() {
      return this.data[this.metric.key]
    }
  }
}
</script>

<style scoped>
.buttonCollapsed {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.buttonNotCollapsed {
  transform: rotate(0deg);
  transition: all 0.5s;
}
.cardCollapsed {
  height: 0;
  transition: all 0.5s;
}
.cardNotCollapsed {
  height: auto;
  transition: all 0.5s;
}
.cardCollapsed * {
  display: none;
}
.titleNoUnderline::after {
  border-bottom: none;
}
.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: -1.25rem;
}
.pt-50 {
  padding-top: 25px !important;
}
.card {
  margin-bottom: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.card-expanded {
  padding-top: 35px;
}
.card-header {
  padding-top: 0;
}
</style>
