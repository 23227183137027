<template>
  <div class="main-wrap">
    <Overlay @close="close" v-if="showOverlay" />
    <!-- Main Header -->
    <header class="main-header header-style-2">
      <div class="header header-bottom header-sticky background-white px-3">
        <div class="logo" @click.prevent="$router.push({ name: 'Home' })">
          <img :src="logo" alt="" />
        </div>
        <div class="menu-items">
          <div class="mr-4 menu-item">
            <a
              href="Om Svealandskusten.se"
              @click.prevent="$router.push({ name: 'AboutKustvatten' })"
              >Om Svealandskusten.se</a
            >
          </div>
          <HamburgerMenu class="hamburger" />
        </div>
      </div>
    </header>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Overlay from '@/components/Overlay.vue'
import HamburgerMenu from '@/components/hamburgerMenu.vue'
// import imgLogo from '@/assets/imgs/svealandskusten-logo.png'
import logo from '@/assets/imgs/logga.svg'

export default {
  name: 'App',
  components: {
    Overlay,
    HamburgerMenu
  },
  data() {
    return {
      logo,
      showOverlay: false
    }
  },
  created() {
    const gtm = document.createElement('script')
    const gtmInit = document.createElement('script')
    gtm.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=G-ML9WH6VSWR'
    )
    gtmInit.setAttribute('src', '/gtm.js')
    document.head.appendChild(gtm)
    document.head.appendChild(gtmInit)
  },
  mounted() {
    if (window.sessionStorage.getItem('userClosedOverlay')) {
      this.showOverlay = false
    } else {
      this.showOverlay = true
    }
  },
  methods: {
    close() {
      window.sessionStorage.setItem('userClosedOverlay', true)
      this.showOverlay = false
    },
    metaInfo() {
      return {
        title: 'Svealandskusten',
        meta: [
          {
            name: 'description',
            content: 'Svealandskusten - Miljöläget längs vår kust'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.main-header {
  position: fixed;
  width: 100%;
  top: 0;
}
.content {
  margin-top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
}
.header {
  background-color: #fff;
  display: flex;
  align-items: center;
}
.menu-items {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header img {
  height: 50px;
  cursor: pointer;
}
.hamburger {
  display: none;
}
.menu-item {
  display: block;
}
@media only screen and (max-width: 990px) {
  .hamburger {
    display: block;
  }
  .menu-item {
    display: none;
  }
}
</style>
