<template>
  <div class="hamburger-container">
    <div class="hamburger-icon" @click="menuVisible = !menuVisible">
      <svg
        height="32px"
        id="Layer_1"
        style="enable-background:new 0 0 32 32;"
        version="1.1"
        viewBox="0 0 32 32"
        width="32px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
        />
      </svg>
    </div>
    <div class="hamburger-menu" v-if="menuVisible">
      <a href="Hem" @click.prevent="goTo('Home')">Hem</a>
      <a href="Om kustvatten.se" @click.prevent="goTo('AboutKustvatten')"
        >Om Svealandskusten.se</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    goTo(name) {
      this.menuVisible = false
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="css" scoped>
.hamburger-container {
  position: relative;
  z-index: 1001;
}
.hamburger-menu {
  z-index: 10101010;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 80px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.hamburger-icon {
  cursor: pointer;
}
.hamburger-menu {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hamburger-menu a {
  display: block;
}
</style>
