<template>
  <div class="chart-mainDiv">
    <div class="lg">
      <div class="row">
        <div class="col-md-12">
          <p class="text" v-if="explainText.length">
            {{ isShowAllText ? explainText.join('. ') : explainText[0] + '.' }}
            <template v-if="isExplainTextExpandable">
              <a href="#" @click.prevent="isShowAllText = !isShowAllText">
                {{ isShowAllText ? 'Visa mindre' : 'Läs mer' }}</a
              >
            </template>
          </p>
        </div>
      </div>
    </div>
    <chart :options="chartData"></chart>
    <!-- <div class="row">
      <div
        class="links ml-3 col-md-12"
        v-for="(link, index) in links"
        v-bind:key="index"
      >
        <a :href="link.url">{{ link.text }}</a>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import axios from 'axios'
import _without from 'lodash/without'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'

export default {
  props: ['title', 'unit', 'id', 'metric', 'data'],
  components: {
    chart: Chart
  },
  data() {
    return {
      links: [],
      rawData: null,
      explanation: [],
      linkText: [],
      sortedLink: [],
      isShowAllText: false
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    isExplainTextExpandable() {
      return this.explainText.length > 1 // One sentence
    },
    explainText() {
      if (!this.explanation.explaination_fulltext) return []
      let text = this.explanation.explaination_fulltext.split('.')
      return text
    },
    chartData() {
      return {
        series: this.createSeries(),
        title: {
          text: this.title,
          x: -20,
          style: {
            fontFamily: "Archivo Narrow',sans-serif",
            color: '#666666',
            fontSize: '16px',
            fontWeight: 'normal'
          }
        },

        xAxis: {
          dateTimeLabelFormats: {
            year: '%Y',
            month: '%Y'
          },

          startOnTick: false,
          endOnTick: true,
          type: 'datetime',
          tickInterval: 365 * 24 * 3600 * 1000,
          labels: {
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#666666',
              fontSize: '12px',
              fontWeight: 'normal'
            },

            overflow: 'justify',
            y: 25
          }
        },
        credits: {
          text: 'Havet.nu',
          href: 'http://www.havet.nu'
        },

        yAxis: {
          labels: {
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#666666',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          },
          title: {
            text: this.unit,
            style: {
              fontFamily: 'Helvetica,Arial,sans-serif',
              color: '#666666',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          },

          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#666666'
            }
          ]
        },

        tooltip: {
          crosshairs: false,

          formatter: function() {
            let datee = new Date(this.x)
            //datee = datee.toLocaleDateString();
            datee = datee.getFullYear()
            // return '<b>' + this.series.name + '<br>Prov togs: ' + datee + '<br><b>' + this.y + '</b>( Salthalt i promille)';
            return '<b>' + this.series.name + '<br>Prov togs: ' + datee
          },

          animation: true,
          enabled: true
        },

        plotOptions: {
          series: {
            shadow: false,
            connectNulls: false,
            stickyTracking: false,

            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        navigation: {
          buttonOptions: {
            enabled: true
          }
        }
      }
    }
  },
  methods: {
    createSeries() {
      let series = [
        this.ytvattenMedelJuliAug(),
        this.ytanJuli(),
        this.ytanAugusti(),
        this.bottenVattenMedelJuliAug(),
        this.bottenJuli(),
        this.bottenAugusti(),
        this.atgrdsomradetMedel()
      ]
      series = _without(series, undefined)
      return series
    },
    ytvattenMedelJuliAug() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'Y' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.medel])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 3,
        color: '#00b5f9',
        zIndex: 3,
        name: 'Ytvatten<br>medelvärde jul-aug',
        data: dataPoint,
        marker: {
          symbol: 'circle',
          fillColor: 'white',
          lineWidth: 2,
          lineColor: '#00b5f9'
        }
      }
    },
    ytanJuli() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'Y' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.juli])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 0,
        color: '#107ac4',
        zIndex: 2,
        name: 'Ytan juli',
        data: dataPoint,
        marker: {
          enabled: true,
          states: { hover: { enabled: true } },
          symbol: 'circle',
          radius: 2.5,
          fillColor: 'white',
          lineWidth: 2,
          lineColor: '#00b5f9'
        }
      }
    },
    ytanAugusti() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'Y' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.aug])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 0,
        color: '#107ac4',
        zIndex: 2,
        name: 'Ytan augusti',
        data: dataPoint,
        marker: {
          enabled: true,
          states: { hover: { enabled: true } },
          symbol: 'circle',
          radius: 2.5,
          fillColor: '#00b5f9',
          lineWidth: 2,
          lineColor: '#00b5f9'
        }
      }
    },
    bottenVattenMedelJuliAug() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'B' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.medel])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 3,
        color: '#107ac4',
        zIndex: 2,
        name:
          this.metric === 'o2H2S'
            ? 'Bottenvatten<br>minimumvärde jul-aug'
            : 'Bottenvatten<br>medelvärde jul-aug',
        data: dataPoint,
        marker: {
          symbol: 'circle',
          fillColor: 'white',
          lineWidth: 2,
          lineColor: '#107ac4'
        }
      }
    },
    bottenJuli() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'B' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.juli])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 0,
        color: '#107ac4',
        zIndex: 2,
        name: 'Botten juli',
        data: dataPoint,
        marker: {
          enabled: true,
          states: { hover: { enabled: true } },
          symbol: 'circle',
          radius: 2.5,
          fillColor: 'white',
          lineWidth: 2,
          lineColor: '#107ac4'
        }
      }
    },
    bottenAugusti() {
      let data
      let dataPoint = []
      data = _filter(this.rawData, { YTA_BTN: 'B' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.aug])
      })
      if (dataPoint.length === 0) {
        return
      }
      return {
        lineWidth: 0,
        color: '#107ac4',
        zIndex: 2,
        name: 'Botten augusti',
        data: dataPoint,
        marker: {
          enabled: true,
          states: { hover: { enabled: true } },
          symbol: 'circle',
          radius: 2.5,
          fillColor: '#107ac4',
          lineWidth: 2,
          lineColor: '#107ac4'
        }
      }
    },
    atgrdsomradetMedel() {
      let data
      let dataPoint = []
      let ytanOrBotten = 'botten'
      data = _filter(this.rawData, { YTA_BTN: 'B' })
      _forEach(data, d => {
        dataPoint.push([d.date, d.atgardsomr_medel])
      })
      if (dataPoint.length === 0) {
        ytanOrBotten = 'ytan'
        data = _filter(this.rawData, { YTA_BTN: 'Y' })
        _forEach(data, d => {
          dataPoint.push([d.date, d.atgardsomr_medel])
        })
      }
      return {
        lineWidth: 10,
        color: '#B0B0B0',
        zIndex: 1,
        name: 'Åtgärdsområdets<br> medelvärde för ' + ytanOrBotten,
        data: dataPoint,
        marker: {
          enabled: false,
          states: { hover: { enabled: true } },
          symbol: 'circle',
          radius: 2.5,
          fillColor: '#107ac4',
          lineWidth: 2,
          lineColor: '#107ac4'
        }
      }
    },
    init() {
      this.rawData = this.data.data
      this.explanation = this.data.explaination
      _forEach(this.rawData, d => {
        d.BES_AR = Number(d.BES_AR)
        d.medel = d.medel ? Number(d.medel) : d.medel
        d.juli = d.juli ? Number(d.juli) : d.medel
        d.aug = d.aug ? Number(d.aug) : d.aug
        d.atgardsomr_medel = d.atgardsomr_medel
          ? Number(d.atgardsomr_medel)
          : d.atgardsomr_medel
        d.atgardsomr_min = d.atgardsomr_min
          ? Number(d.atgardsomr_min)
          : d.atgardsomr_min
        d.atgardsomr_max = d.atgardsomr_max
          ? Number(d.atgardsomr_max)
          : d.atgardsomr_max
        d.atgardsomr_periodmin = d.atgardsomr_periodmin
          ? Number(d.atgardsomr_periodmin)
          : d.atgardsomr_periodmin
        d.atgardsomr_periodmax = d.atgardsomr_periodmax
          ? Number(d.atgardsomr_periodmax)
          : d.atgardsomr_periodmax
        d.date = Date.UTC(d.BES_AR, 1, 1)
      })
      this.createLinks(this.explanation.explaination_links)
    },
    createLinks(strLinks) {
      let splitString
      let newStringsAfterSplit = []
      if (strLinks == null) {
        return
      }
      splitString = strLinks.split('##\r\n')
      // }
      splitString.forEach(element => {
        let link = element.split('==')
        this.links.push({ text: link[0], url: link[1] })
      })
      newStringsAfterSplit.forEach(element => {
        if (element) {
          this.links.push({ text: element[0], url: element[1] })
        }
      })
    }
  }
}
</script>

<style>
legend {
  margin-top: 10px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.text {
  margin-top: 10px;
  text-align: left;
}
.LearnMore {
  text-align: left;
  font-size: large;
  font-weight: bold;
}
.links {
  text-align: left;
  padding: 0;
}
</style>
