<template>
  <section class="main-content-container">
    <div class="rapporter-container">
      <div class="header">
        <h4>Rapporter Svealandskusten</h4>
      </div>
      <p class="my-4">
        Den populärvetenskapliga rapporten Svealandskusten sammanfattar
        miljötillståndet i kustvattnen från Dalälvens mynning i norr till
        Bråviken i söder. Rapporten innehåller resultat från de undersökningar
        som bedrivs i Svealands kustvattenvårdsförbunds regi tillsammans med
        artiklar från andra aktörer i regionen.
      </p>
      <p>
        <b>Läs mer</b><br />
        Rapporten Svealandskusten ges ut av
        <a href="https://www.skvvf.se/">Svealands kustvattenvårdsförbund</a>, en
        ideell förening vars medlemmar utgörs av kommuner, länsstyrelser,
        företag och intresseföreningar i regionen. Undersökningsverksamhet och
        rapportering leds av
        <a
          href="https://www.su.se/stockholms-universitets-ostersjocentrum/om-centrumet/samarbeten/svealands-kustvattenv%C3%A5rdsf%C3%B6rbund-1.561128?open-collapse-boxes=contact-555666777"
          >marin expertis från Stockholms universitet</a
        >.
      </p>
      <div class="search-row mt-30">
        <div class="input-group input-group-lg">
          <input
            v-model="searchTerm"
            placeholder="Sök efter rapport här. Du kan söka på ämne, författare, datum eller titlar"
            type="text"
            class="form-control"
            aria-label="Search"
          />
        </div>
        <a class="rounded-btn" href="Sök" @click.prevent="search">Sök</a>
      </div>
      <div class="topics">
        <div
          class="topic rounded-btn"
          @click="
            () => {
              articles = []
              searchTerm = 'Svealandskusten'
            }
          "
        >
          Hela rapporten
        </div>
        <div
          class="topic rounded-btn"
          @click="seachByTopic(topic.title)"
          v-for="topic in topics"
          :key="topic.id"
        >
          {{ topic.title }}
        </div>
      </div>
      <div v-if="loading" class="mt-60" style="text-align: center;">
        Laddar...
      </div>
      <div class="articles mt-60">
        <h5>Rapporter i flödet</h5>
        <div class="article-rapporter-container">
          <template v-if="articles.length > 0">
            <RapportCard
              v-for="article in articles"
              :key="article.id"
              :article="article"
            />
          </template>
          <template v-else>
            <PageCard
              v-for="page in reportPages"
              :key="page.pageId"
              :page="page"
            />
          </template>
        </div>

        <!-- <div v-if="!articles.length && !loading" style="text-align: center;">
          Tyvärr kunde vi inte hitta några rapporter som matchar din sökning.
        </div> -->
      </div>
    </div>
    <div class="pt-60 back-link d-flex justify-content-center">
      <a
        class="color-orange-default text-underlined mon-rat-alt-reg"
        href="Tillbaka till karta"
        @click.prevent="$router.push({ name: 'Home' })"
        >Tillbaka till karta</a
      >
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import RapportCard from '@/components/RapportCard'
import PageCard from '@/components/PageCard'
export default {
  components: {
    RapportCard,
    PageCard
  },
  data() {
    return {
      articles: [],
      loading: false,
      searchTerm: '',
      reportPages: [
        {
          pageId: 3695,
          title: 'Svealandskusten 2022',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/4746.jpg'
        },
        {
          pageId: 3650,
          title: 'Svealandskusten 2021',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2689.jpg'
        },
        {
          pageId: 3623,
          title: 'Svealandskusten 2020',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/4932.jpg'
        },
        {
          pageId: 3591,
          title: 'Svealandskusten 2019',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2983.jpg'
        },
        {
          pageId: 3567,
          title: 'Svealandskusten 2018',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2915.jpg'
        },
        {
          pageId: 3540,
          title: 'Svealandskusten 2017',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/5528.jpg'
        },
        {
          pageId: 3490,
          title: 'Svealandskusten 2016',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2649.jpg'
        },
        {
          pageId: 3450,
          title: 'Svealandskusten 2015',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/5839.jpg'
        },
        {
          pageId: 3406,
          title: 'Svealandskusten 2014',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2561.jpg'
        },
        {
          pageId: 3241,
          title: 'Svealandskusten 2013',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/4425.jpg'
        },
        {
          pageId: 285,
          title: 'Svealandskusten 2012',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/3478.jpg'
        },
        {
          pageId: 268,
          title: 'Svealandskusten 2011',
          image: 'https://havetstore.blob.core.windows.net/bilder/870/2945.jpg'
        }
      ],
      topics: [
        { id: 1, title: 'Fisk' },
        { id: 2, title: 'Åtgärder' },
        { id: 3, title: 'Gift' },
        { id: 4, title: 'Övergödning' }
      ]
    }
  },
  async mounted() {
    // const url = `https://havet-api.azurewebsites.net/SvealandskustenMisc/latest`
    // const { data } = await axios.get(url)
    // this.articles = data
    // this.loading = false
  },
  methods: {
    seachByTopic(topic) {
      this.searchTerm = topic
      this.search()
    },
    async search() {
      this.articles = []
      this.loading = true
      const url = `https://havet-api.azurewebsites.net/SvealandskustenMisc/rapporter?search=${this.searchTerm}`
      const { data } = await axios.get(url)
      this.articles = data
      this.loading = false
    }
  }
}
</script>

<style lang="css" scoped>
.rapporter-container {
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
  padding: 20px;
}
.header {
  margin-top: 40px;
  color: #007bff;
  display: flex;
  justify-content: center;
}
.search-row {
  display: flex;
  gap: 20px;
}
.article-container {
  max-width: 100%;
  display: flex;
  /* gap: 20px;xw */
  flex-wrap: wrap;
}
.topics {
  max-width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.topic {
  display: flex;
  justify-content: center;
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .topics {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .topic {
    max-width: 400px;
    min-width: 200px;
  }
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  display: flex;
  align-items: center;
}
.article-rapporter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
