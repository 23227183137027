<template>
  <div class="container">
    <div v-if="loading" style="text-align:center">Laddar Svealandskusten..</div>

    <main v-if="data">
      <div
        class="image"
        :style="{ 'background-image': 'url(' + image + ')' }"
      ></div>
      <section class="section mt-2">
        <div class="text">
          <h4>{{ item.item_page_title }}</h4>
          <p v-html="item.item_text"></p>
        </div>
        <div class="fig mt-10">
          <h5>Innehåll</h5>
          <ul class="mb-30 ml-15">
            <template v-for="(link, index) in links">
              <li
                :key="index"
                class="mb-5"
                v-if="index === 0"
                style="list-style: none;"
              >
                <a
                  class="rounded-btn mb-20"
                  :href="link.link.link_url"
                  target="_blank"
                  >Ladda ned</a
                >
              </li>
              <li v-else :key="index" class="mb-5">
                <a :href="link.link.link_url">{{ link.link.link_title }}</a>
              </li>
            </template>
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Page',
  props: {
    id: [String, Number]
  },
  data() {
    return {
      data: null,
      loading: true
    }
  },
  async mounted() {
    const url = `https://havet-api.azurewebsites.net/pages/${this.id}`
    const { data } = await axios.get(url)
    this.data = data
    this.loading = false
  },
  computed: {
    item() {
      return this.data.items[0] // We only need the first innehållsblock
    },
    image() {
      return `https://havetstore.blob.core.windows.net/bilder/870/${this.item.itemImages[0].image.image_id}.jpg`
    },
    links() {
      const links = this.data.items[0].itemLinks
      return links.sort((a, b) => a.il_order - b.il_order)
    }
  }
}
</script>

<style lang="css" scoped>
li {
  list-style: unset;
}
.container {
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.image {
  height: 400px;
  width: 100%;
  background-color: mediumaquamarine;
}
.section {
  display: flex;
  width: 100%;
  font-size: 14px;
}
.text {
}
.fig {
  min-width: 250px;
  display: inline;
  background-color: #f7f7f7;
  border-top: 5px solid #007bff;
  padding: 15px;
  margin-left: 10px;
  height: fit-content;
  padding-bottom: 20px;
  font-size: 13px;
}
@media only screen and (max-width: 768px) {
  .section {
    flex-direction: column;
  }
  .fig {
    min-width: unset;
    margin-left: 0;
  }
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  display: block;
  text-align: center;
}
</style>
