<template>
  <div class="container">
    <div v-if="loading" style="text-align:center">Laddar rapport..</div>
    <main v-if="data">
      <div
        class="image"
        :style="{ 'background-image': 'url(' + image + ')' }"
      ></div>
      <section class="mt-2 section">
        <div class="text">
          <h4>{{ data.article_title }}</h4>
          <p v-html="articleDescription"></p>
        </div>
        <div class="fig mt-10">
          <ul class="mb-30">
            <li>Utgiven av: {{ data.article_name }}</li>
            <li>Utgiven år: {{ data.article_year }}</li>
            <li>Författare: {{ data.article_author }}</li>
          </ul>
          <a class="rounded-btn" :href="fileUrl" target="_blank">Ladda ned</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Rapport',
  props: {
    id: [String, Number]
  },
  data() {
    return {
      data: null,
      loading: true
    }
  },
  async mounted() {
    const url = `https://havet-api.azurewebsites.net/article/${this.id}`
    const { data } = await axios.get(url)
    this.data = data
    this.loading = false
  },
  computed: {
    articleDescription() {
      return this.data.article_description
    },
    fileUrl() {
      const fileUrl = this.data.article_file
      if (fileUrl.startsWith('http')) return fileUrl
      return `https://havetstore.blob.core.windows.net/dokument/${fileUrl}`
    },
    image() {
      return (
        'https://havetstore.blob.core.windows.net/bilder/870/' +
        this.data.article_image_id +
        '.jpg'
      )
    }
  }
}
</script>

<style lang="css" scoped>
.container {
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.image {
  height: 400px;
  width: 100%;
  background-color: mediumaquamarine;
}
.section {
  display: flex;
  width: 100%;
  font-size: 14px;
}
.text {
}
.fig {
  min-width: 250px;
  display: inline;
  background-color: #f7f7f7;
  border-top: 5px solid #007bff;
  padding: 15px;
  margin-left: 10px;
  height: fit-content;
  padding-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .section {
    flex-direction: column;
  }
  .fig {
    min-width: unset;
    margin-left: 0;
  }
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  display: block;
  text-align: center;
}
b {
  font-family: 'Roboto', sans-serif;
  font-weight: 900 !important;
}
</style>
