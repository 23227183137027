import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import InfoStn from './views/InfoStn.vue'
import InfoVik from './views/InfoVik.vue'
import About from './views/About.vue'
import AboutMap from './views/AboutMap.vue'
import Rapporter from './views/Rapporter.vue'
import Rapport from './views/Rapport.vue'
import Page from './views/Page.vue'

Vue.use(Router)
const castRouteParams = function(route) {
  return {
    id: Number(route.params.id),
    name: route.params.name
  }
}
export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/station/:name/',
      name: 'StationSelect',
      component: Home,
      props: castRouteParams
    },
    {
      path: '/vik/:name/',
      name: 'VikSelect',
      redirect: { name: 'Home' }
    },
    {
      path: '/station/:name/:id/data',
      name: 'StationInfo',
      component: InfoStn,
      props: castRouteParams
    },
    {
      path: '/vik/:name/:id/data',
      name: 'VikInfo',
      redirect: { name: 'Home' }
    },
    {
      path: '/om-svealandskusten',
      name: 'AboutKustvatten',
      component: About
    },
    {
      path: '/hur-funkar-kartan',
      name: 'AboutKartan',
      component: AboutMap
    },
    {
      path: '/rapporter',
      name: 'Rapporter',
      redirect: { name: 'Home' }
    },
    {
      path: '/rapporter/:id',
      name: 'Rapport',
      redirect: { name: 'Home' }
    },
    {
      path: '/page/:id',
      name: 'Page',
      redirect: { name: 'Home' }
    }
  ]
})
