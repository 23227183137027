<template>
  <div class="overlay-backdrop">
    <div
      class="overlay d-flex justify-content-center align-items-center flex-wrap"
    >
      <section>
        <h1>Svealandskusten</h1>
        <div class="col-12"><img :src="bgImage" alt="" /></div>
        <div class="col-12 mb-10 mt-20">
          <InfoText />
        </div>
        <div class="footer-button">
          <a class="rounded-btn" href="Fortsätt" @click.prevent="close"
            >Fortsätt</a
          >
        </div>
      </section>
      <div class="close" @click="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#5f6368"
          stroke="#5f6368"
          stroke-width="3.232"
          stroke-linecap="round"
          xmlns:v="https://vecta.io/nano"
        >
          <path d="M3.966 3.567L21.401 21.78" />
          <path d="M3.966 21.78L21.401 3.567" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/imgs/overlay-image.png'
import InfoText from '@/components/InfoText.vue'

export default {
  name: 'overlay',
  components: {
    InfoText
  },
  data() {
    return {
      bgImage
    }
  },
  mounted() {
    let main = document.querySelector('.main-wrap')
    main.style.pointerEvents = 'none'
  },
  beforeDestroy() {
    let main = document.querySelector('.main-wrap')
    main.style.pointerEvents = 'auto'
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="css" scoped>
.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  pointer-events: none;
}
.overlay {
  background-image: url(/img/bakgrunden10VIT.493c870a.png);
  background-color: white;
  width: 60vw;
  height: 80vh;
  z-index: 10000000000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  border-radius: 32px;
  border: #007bff 2px solid;
  padding: 10px;
  pointer-events: auto !important;
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
}
.close svg {
  height: 25px;
  width: 25px;
}
.footer-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.rounded-btn {
  font-family: Montserrat-Bold;
  background-color: #f79425;
  color: white;
  padding: 10px 15px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
}
section {
  padding: 50px;
  overflow: scroll;
  max-height: 100%;
  max-width: 100%;
}
h1 {
  color: #f79425;
  text-align: center;
  margin-bottom: 30px;
}
h3 {
  color: #007bff;
  margin-bottom: 5px;
}

@media only screen and (max-width: 900px) {
  .overlay {
    width: 80vw;
  }
  section {
    padding: 30px 0px;
  }
  h3 {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 1.5rem;
  }
}
div {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
}
.bold-text {
  font-weight: bold;
}
ul {
  padding-left: 40px;
}
li {
  list-style: unset;
  margin: 10px 0;
}
</style>
