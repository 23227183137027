<template>
  <div>
    <h5 class="mt-3">Miljöläget längs vår kust</h5>
    <p class="bold-text my-3">
      Här presenteras resultaten från Svealands kustvattenvårdsförbunds
      omfattande provtagningsverksamhet i skärgårdens alla fjärdar och vikar –
      från Dalälvens mynning i norr till Bråviken i söder.
    </p>
    <p>
      Närmare 200 fjärdar längs hela Svealandskusten undersöks årligen sedan
      2001. Här visas resultaten för varje fjärd tillsammans med korta
      förklaringar av variablerna.
    </p>
    <section v-if="fjardarExpanded">
      <p class="bold-text">Om figurerna – Mätningar av högsta kvalitet</p>
      <p>
        För varje provtagningsplats visas trenddiagram och i förekommande fall
        ekologisk status över salthalt, temperatur, siktdjup, syrehalt,
        näringsämnen och klorofyll. Samtliga variabler förklaras och presenteras
        närmare. För att sätta mätresultaten i någon slags relevant relation,
        visas i diagrammen också medelvärdet för det större åtgärdsområde dit
        stationen hör.
      </p>
      <p>
        Vattnet undersöks i juli och augusti varje år på samtliga
        provtagningsstationer längs Svealandskusten. Mätningarna görs med högsta
        kvalitet och helt enligt nationella riktlinjer. Omfattningen av
        provtagningen är unik i landet eftersom så gott som varje vattenområde i
        Svealand undersöks. Kunskapen om miljötillståndet i vattnet och
        bottnarnas syretillgång är således mycket god.
      </p>
      <p class="bold-text">Om undersökningarna</p>
      <p>
        Om undersökningarna Undersökningarna sker på uppdrag av Svealands
        kustvattenvårdsförbund (<a target="_blank" href="https://www.skvvf.se/"
          >https://www.skvvf.se/</a
        >), en ideell förening vars medlemmar utgörs av kommuner, länsstyrelser,
        landsting, företag och intresseföreningar i regionen. Målsättningen är
        att bygga upp en gemensam kunskapsbas över miljötillståndet för att
        lättare kunna prioritera åtgärder för att nå god vattenkvalitet.
        Förbundet strävar också efter utökad samordning med olika lokala
        recipientkontrollprogram.
      </p>
      <p>
        Undersökningsverksamhet och rapportering leds av marin expertis från
        Stockholms universitet. Institutionen för ekologi, miljö och botanik (<a
          href="https://www.su.se/forskning/forskargrupper/marinekologiska-laboratoriet-mel/mel-pelagial-1.622559"
          >https://www.su.se/forskning/forskargrupper/marinekologiska-laboratoriet-mel/mel-pelagial-1.622559</a
        >) håller i den omfattande provtagningsverksamheten som utförs med samma
        höga kvalitet som den nationella miljöövervakningen. Institutionen
        inventerar, kvalitetsgranskar och sammanställer dessutom andra typer av
        relevanta miljödata. Allt detta underlag hålls i en databas, och utgör
        grunden för sammanställningar och miljöanalyser som görs i samverkan med
        forskare på Östersjöcentrum (
        <a
          href="https://www.su.se/stockholms-universitets-ostersjocentrum/om-centrumet/samarbeten/svealands-kustvattenv%C3%A5rdsf%C3%B6rbund-1.561128"
          >https://www.su.se/stockholms-universitets-ostersjocentrum/om-centrumet/samarbeten/svealands-kustvattenv%C3%A5rdsf%C3%B6rbund-1.561128</a
        >).
      </p>
      <p>
        Resultaten av de omfattande provtagningarna används av vattenvårdande
        myndigheter och redovisas på denna webbplats och i den årliga
        populärvetenskapliga rapporten Svealandskusten (
        <a href="https://www.skvvf.se/svelandskusten/"
          >https://www.skvvf.se/svelandskusten/</a
        >).
      </p>
    </section>

    <a href="Läs mer" @click.prevent="fjardarExpanded = !fjardarExpanded">{{
      fjardarExpanded ? 'Visa mindre' : 'Läs mer'
    }}</a>

    <div class="pt-60 back-link d-flex justify-content-center">
      <a
        class="color-orange-default text-underlined mon-rat-alt-reg"
        href="Tillbaka till karta"
        @click.prevent="$router.push({ name: 'Home' })"
        >Tillbaka till karta</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fjardarExpanded: false
    }
  }
}
</script>

<style lang="css" scoped>
div {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
}
.bold-text {
  font-weight: bold;
}
ul {
  padding-left: 40px;
}
li {
  list-style: unset;
  margin: 10px 0;
}
</style>
